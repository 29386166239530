import Axios from 'axios';

import { notify } from 'src/notify.js';
import { getToken } from 'src/utils/security.js';
import { captureException } from 'src/utils/sentry.js';

const token = getToken();
export const apiUrl = '/api/frontend';
export const apiUrlV1 = '/api/frontend_v1';

export const axiosInstance = Axios.create({
  headers: {
    Accept: 'application/json',
    'X-CSRF-Token': token,
  }
});

/**
 * @param {{headers: (Record<string, string>|{}), query?: Record<string, string>, url: string}} params
 * @param {string} params.url
 * @param {Record<string, string>} [params.headers]
 * @param {Record<string, string>} [params.query]
 * @return {AxiosPromise}
 */
export const cancellableRequest = (params) => {
  const { cancel, token: cancelToken } = Axios.CancelToken.source();
  const request = axiosInstance({
    ...params,
    cancelToken,
  });
  request.cancel = cancel;
  return request;
};

export function cancelRequest(request) {
  if (request && request.cancel) request.cancel();
}

export function isCancelledRequest(error) {
  return Axios.isCancel(error);
}

export function isAxiosError(error) {
  return error && error.isAxiosError;
}

// eslint-disable-next-line complexity
export function handleRequestError(error) {
  if (error.response && error.response.data && error.response.data.error && error.response.data.error.message === 'Bad email') {
    window.location.href = '/'
    return;
  }
  if (isCancelledRequest(error)) return;
  const { message = error } = error || {};
  notify({ message, type: 'error' });
  captureException(error);
}

export function showErrorNotification(error) {
  if (isCancelledRequest(error)) return;

  let message;
  if (isAxiosError(error)) {
    message = error.response.data.error?.message;
  } else {
    captureException(error);
  }

  if (!message) {
    message = error.toString();
  }

  notify({ message, type: 'error' });
}

export function handleApiErrors(errors) {
  errors.forEach(({ message }) => {
    notify({ message, type: 'error' });
  });
}

/**
 * @param {{headers: (Record<string, string>|{}), query?: Record<string, string>, url: string}} params
 * @param {string} params.url К урлу будет добавлен префикс `.js`, чтобы рельсы вернули именно js, просто указывать Accept недостаточно
 * @param {Record<string, string>} [params.headers]
 * @param {Record<string, string>} [params.query]
 * @return {AxiosPromise}
 */
export function cancellableJsRequest(params) {
  const url = `${params.url}.js`;
  const headers = params.headers || {};
  headers.Accept = 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript';

  return cancellableRequest({
    ...params,
    url,
    headers
  })
}
