<template>
  <div class="news-subscribe-landing">
    <BasicForm
      v-if="!isSent"
      :action="subscribeUser"
      class="news-subscribe-landing__form"
      @success="onSubscribe"
      v-slot="{ requestInProcess }"
    >
      <FormField
        name="email"
        v-slot="{ invalid }"
      >
        <TextInput
          v-model="email"
          class="news-subscribe-landing__input"
          type="email"
          name="email"
          :invalid="invalid"
          placeholder="Электропочта"
        />
      </FormField>
      <BasicButton
        class="news-subscribe-landing__button"
        appearance="primary"
        size="xl"
        type="submit"
        :disabled="requestInProcess"
      >
        Подписаться
      </BasicButton>
    </BasicForm>
    <div
      v-else
      class="news-subscribe-landing__success"
    >
      <div class="news-subscribe-landing__success-title">Подписка создана</div>
      <div v-html="successText" class="news-subscribe-landing__success-text" />
    </div>
  </div>
</template>

<script>
  import { GuestService } from 'src/services/guest.js';
  import { SubscribeService } from 'src/services/subscribe.js';
  import { setInsecureCookie } from 'src/utils/cookies.js';

  import BasicButton from 'src/components/BasicButton/BasicButton.vue';
  import BasicForm from 'src/components/form/BasicForm/BasicForm.vue';
  import FormField from 'src/components/form/FormField/FormField.vue';
  import TextInput from 'src/components/form/TextInput/TextInput.vue';

  const NEWS_SUBSCRIBE_FLAG = 'has_news_subscribe';
  const NEWS_SUBSCRIBE_BREAK = 24 * 60 * 60; // 1 сутки

  export default {
    name: 'NewsSubscribeLanding',
    components: {
      BasicForm,
      BasicButton,
      TextInput,
      FormField,
    },
    data() {
      return {
        email: '',
        userEmail: '',
        isSent: false,
      }
    },
    methods: {
      subscribeUserAction: SubscribeService.subscribeUser,
      subscribeGuestAction: GuestService.createSubscription,
      subscribeUser() {
        return GuestService.createSubscription({ email: this.email, type: 'news'})
      },
      onSubscribe({ email }) {
        this.userEmail = email;
        this.isSent = true;
          setInsecureCookie(
            NEWS_SUBSCRIBE_FLAG,
            true,
            {'max-age': NEWS_SUBSCRIBE_BREAK}
          );
      },
    },
    computed: {
      successText() {
        return `Скоро на ${this.email} придет письмо. <br> Подтвердите подписку, если всё в силе.`
      }
    }
  }
</script>