import { createVueApp } from 'src/utils/vue.js';

import NewsSubscribeLanding from 'src/components/NewsSubscribeLanding/NewsSubscribeLanding.vue'

const app = createVueApp({
  render(h) {
    return h(NewsSubscribeLanding, {
    })
  },
});

app.$mount('[data-subscribe-landing]');