import { apiUrlV1, cancellableRequest } from 'src/services/api.js';

export const GuestService = {
  createSubscription(data) {
    return cancellableRequest({
      method: 'post',
      url: `${apiUrlV1}/guest_subscriptions`,
      data,
    });
  },
};
