export function setInsecureCookie(name, value, options) {
  let cookie = `${name}=${value}`;

  if (options) {
    const optionsString = Object.entries(options)
      .map(([key, optValue]) => `${key}=${optValue}`)
      .join(';');
    cookie += `;${optionsString}`;
  }

  document.cookie = cookie;
}

export function getCookie(name) {
  const nameWithEquals = `${name}=`;
  const cookie = document
    .cookie
    .split('; ')
    .find(c => c.startsWith(nameWithEquals));

  if (cookie) {
    return cookie.substring(nameWithEquals.length);
  }

  return null;
}
