import { apiUrlV1, cancellableRequest } from 'src/services/api.js';

export const SubscribeService = {
  subscribeUser() {
    return cancellableRequest({
      url: `${apiUrlV1}/users/subscribe_to_notifications`,
      method: "patch"
    });
  },
  getSubscribeInfo() {
    return cancellableRequest({
      url: `${apiUrlV1}/users/notification_subscribe_data`,
      method: "get"
    });
  },
}